import * as React from 'react';
import {useState} from "react";
import {useEffect} from "react";
import {getMatchRegistration, setUserPresentAtMatch, showPresentUsersByMatch} from "../../../services/match.service";
import {Layout, Modal} from "antd";
import PresentSupportsTable from "./PresentSupportsTable";
import QrReader from "react-qr-reader";

const MatchSupporters = (props: any) => {
    const [presentUsers, setPresentUsers] = useState([]);
    const [dataFound, setDataFound] = React.useState();

    const getUsers = (userList: any) => {
        setPresentUsers(userList);
    };

    const handleScan = async (data: string) => {
        if (data && !dataFound) {
            setDataFound(true);
            const [matchId, userId] = data.split(';');

            if (props.match.params.id === matchId) {
                const userData = await getMatchRegistration(matchId, userId);
                if (userData) {
                    showSuccesModal(userData);
                    setUserPresentAtMatch(matchId, userId);
                }
            }
        }
    };
    const handleError = (err: any) => {
        console.error(err)
    };

    const showSuccesModal = (user: any) => {
        Modal.success({
            onOk: () => setDataFound(false),
            content: (<>
                <br/>
                Persoon: {user.name}
                <br/>
                Aantal volwassenen: {user.houseHold}
                <br/>
                Aantal kinderen: {user.houseHoldYoung}
                <br/>
                Kantine bezoeken: {user.canteen ? 'Ja' : 'Nee'}
            </>),
        });
    };

    useEffect(() => {
        const matchId = props.match.params.id;
        showPresentUsersByMatch(matchId, getUsers);

        return () => {
            // Anything in here is fired on component unmount./
        }
    }, [props.match.params.id]);

    return (
        <Layout style={{padding: '20px', height: '100%'}}>
            <QrReader
                delay={500}
                onError={handleError}
                onScan={handleScan}
            />
            <PresentSupportsTable listOfSupporters={presentUsers}/>
        </Layout>
    )
};

export default MatchSupporters
