import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {PrivateRoute} from "../helper/route.helper";

import {UsersRoutes} from "../views/Users";
import {DashboardRoutes} from "../views/Dashboard";
import {MatchAdminRoutes, MatchPublicRoutes} from "../views/Match";
import {AuthRoutes} from "../views/Auth";

const ApplicationRoutes = () => {
    return (
        <Router>
            <Switch>
                <PrivateRoute exact path="/admin/dashboard" component={DashboardRoutes}/>
                <PrivateRoute exact path="/admin/users" component={UsersRoutes}/>
                <PrivateRoute exact path="/admin/matches" component={MatchAdminRoutes}/>
                <PrivateRoute exact path="/admin/matches/:id" component={MatchAdminRoutes}/>
                <PrivateRoute exact path="/admin/matches/:id/supporters" component={MatchAdminRoutes}/>
                <PrivateRoute exact path="/admin/registration" component={MatchAdminRoutes}/>
                <PrivateRoute exact path="/matches" component={MatchPublicRoutes}/>
                <Route path="/auth" component={AuthRoutes}/>
                <Redirect to="/auth" from="/"/>
            </Switch>
        </Router>
    );
}

export default ApplicationRoutes;
