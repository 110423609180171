import * as React from 'react';
import {useEffect} from "react";
import {
    deleteCurrentUserFromMatch,
    fetchMatches,
    getMatchUserById,
    signUpForMatch
} from "../../../services/match.service";
import SignUpMatchModal from "./signUpMatch.modal";
import {Match as MatchType, MatchRegistration} from "../../../types/match.type";
import Match from "./Match";
import moment from 'moment';
import QRCode from "react-qr-code";
import {List, Modal} from "antd";
import {useContext} from "react";
import {AuthContext} from "../../../helper/auth.helper";
import firebase from "../../../firebase";

const MatchesPublicPage = () => {
    const authContext = useContext(AuthContext);
    const {user} = authContext;
    const [selectedMatch, setSelectedMatch] = React.useState();
    const [selectedMatchDetails, setSelectedMatchDetails] = React.useState();
    const [isModalLoading, setIsModalLoading] = React.useState();
    const [showQRCode, setShowQRCode] = React.useState<{ showQRCode: boolean, selectedMatch: MatchType }>({
        selectedMatch: {} as MatchType,
        showQRCode: false
    });
    const [showRegistrationModal, setShowRegistrationModal] = React.useState();
    const [matches, setMatches] = React.useState();

    const onSignUpdMatchModalClose = () => {
        setShowRegistrationModal(false);
    };

    const matchRegistration = async (match: MatchType) => {
        const matchDetails = await getMatchUserById(match.id);
        await updateMatches();
        setSelectedMatch(match);
        setSelectedMatchDetails(matchDetails);
        setShowRegistrationModal(true);
    };

    const onSubmitRegistration = async (values: any) => {
        const matchRegistration: MatchRegistration = {
            ...values,
            ...selectedMatch
        };

        setShowRegistrationModal(false);
        setIsModalLoading(true);
        await signUpForMatch(matchRegistration);
        await updateMatches();
        setIsModalLoading(false);
    };

    useEffect(() => {
        (async () => {
            if (!isModalLoading) await updateMatches();
        })();

        return () => {
            // Anything in here is fired on component unmount.
        }
    }, []);

    const updateMatches = async () => {
        const listOfMatches = await fetchMatches();
        listOfMatches.sort((a, b) => moment(a.dateTime).diff(moment(b.dateTime)));
        setMatches(listOfMatches);
    };

    const onUnsubscribeMatch = async () => {
        setShowRegistrationModal(false);
        setIsModalLoading(true);
        await deleteCurrentUserFromMatch(selectedMatch);
        await updateMatches();
        setIsModalLoading(false);
    };

    const generateQRCode = (match: MatchType) => {
        setSelectedMatch(match);
        setShowQRCode({
            selectedMatch: match,
            showQRCode: true
        });
    };

    const renderMatchItem = (match: any) => {
        return <Match
            qrCode={() => generateQRCode(match)}
            signUp={() => matchRegistration(match)}
            match={match}
        />
    };



    if (!user) return <></>;

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={matches}
                renderItem={renderMatchItem}
            />
            <SignUpMatchModal onClose={onSignUpdMatchModalClose}
                              isLoading={isModalLoading}
                              onRegistration={(values: any) => onSubmitRegistration(values)}
                              onUnsubscribeMatch={onUnsubscribeMatch}
                              isVisible={showRegistrationModal}
                              match={selectedMatch}
                              matchDetails={selectedMatchDetails}/>
            <Modal
                destroyOnClose={true}
                visible={showQRCode.showQRCode}
                title={`QRCode`}
                onOk={() => setShowQRCode({selectedMatch: {} as MatchType, showQRCode: false})}
                onCancel={() => setShowQRCode({selectedMatch: {} as MatchType, showQRCode: false})}
            >
                <div className="QRCode">
                    <QRCode value={showQRCode.selectedMatch.id + ';' + firebase.auth().currentUser!.uid}/>
                </div>
            </Modal>
        </>
    )
};

export default MatchesPublicPage
