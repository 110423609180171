import * as React from 'react';
import UserTable from "./UserTable";
import {useEffect, useState} from "react";
import {fetchUsers} from "../../../services/user.service";
import {Layout} from "antd";

const UsersPage = () => {
    const [users, setUsers] = useState();

    useEffect(() => {
        (async () => {
            const usersList = await fetchUsers();
            setUsers(usersList);
        })();
    }, []);


    return (
        <Layout style={{padding: '20px', height: '100%'}}>
            <UserTable users={users}/>
        </Layout>
    )
};

export default UsersPage;
