import firebase from '../firebase';
import User from "../types/user.type";

const isUserExists = async () => {
    const db = firebase.firestore();
    const doc = await db
        .collection("users")
        .doc(firebase.auth().currentUser!.uid)
        .get()
    return doc.exists;
};

const createUser = async (userProfile: User) => {
    const db = firebase.firestore();
    if (!userProfile.user) return null;
    if (await isUserExists()) throw TypeError("User is already in the database");

    if (!userProfile.name || !userProfile.email || !userProfile.phone || !firebase.auth().currentUser!.uid) {
        deleteUser(firebase.auth().currentUser);
        throw TypeError('Geen geldie registratie');
    }

    return db.collection("users").doc(firebase.auth().currentUser!.uid).set({
        name: userProfile.name,
        email: userProfile.email,
        phone: userProfile.phone,
        hash: userProfile.hash
    }).then(function () {
        return userProfile;
    }).catch(function (error) {
        deleteUser(firebase.auth().currentUser);
        throw TypeError(error);
    });
};

const deleteUser = (currentUser: any) => {
    currentUser.delete().then(() => {
    }).catch((error: Error) => {
        console.error("Error deleting user: ", error);
    });
};

const getCurrentUser = () => {
    const db = firebase.firestore();
    return db.collection("users")
        .doc(firebase.auth().currentUser!.uid)
        .get()
        .then(res => {
            return res.data();
        })
};

const getUserById = (userId: string) => {
    const db = firebase.firestore();
    return db.collection("users")
        .doc(userId)
        .get()
        .then(res => {
            return res.data();
        })
};

const fetchUsers = () => {
    const db = firebase.firestore();
    return db.collection('users').get()
        .then(snap => {
            return snap.docs.map((doc, index) => {
                return {
                    ...doc.data(),
                    key: index,
                    id: doc.id,
                    ref: doc.ref
                };
            })
        });
};

export {
    createUser,
    fetchUsers,
    getCurrentUser,
    getUserById
}
