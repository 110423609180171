import * as React from 'react';
import {Layout, Form, Input, Button, InputNumber} from 'antd';

import firebase from '../../../firebase'
import {useContext} from "react";
import {AuthContext} from "../../../helper/auth.helper";
import {createUser} from "../../../services/user.service";
import User from "../../../types/user.type";
import {useHistory} from 'react-router-dom';
import {ROUTES} from "../../../config/routes.config";
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";
import {useState} from "react";
import {LoadingScreen} from "../../Loading/Loading";
import {Toast} from "antd-mobile";
import {FirebaseErrors} from "../../../helper/FirebaseErrors.helper";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

type FormValues = {
    name: string,
    email: string,
    phone: string,
    password: string,
    confirm: string,
}

const Signup = () => {
    const {Content} = Layout;
    const [nameHasValue, setNameHasValue] = useState();
    const [emailHasValue, setEmailHasValue] = useState();
    const [passwordHasValue, setPasswordHasValue] = useState();
    const [confirmPasswordHasValue, setConfirmPasswordHasValue] = useState();
    const [phoneHasValue, setPhoneHasValue] = useState();


    const authContext = useContext(AuthContext);
    const {loadingAuthState, authenticated} = useContext(AuthContext);
    const history = useHistory();
    const redirectToTargetPage = () => {
        history.push(ROUTES.matches);
    };

    if (authenticated) {
        redirectToTargetPage();
    }
    const onFinish = async (values: FormValues) => {
        firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then(async (userCredentials: firebase.auth.UserCredential) => {
                const userProfile: User = {
                    ...userCredentials,
                    email: values.email,
                    name: values.name,
                    phone: values.phone,
                    hash: values.password
                };

                await createUser(userProfile);
                authContext.setUser(userCredentials);
                history.push(ROUTES.matches);
            })
            .catch(error => {
                Toast.fail(FirebaseErrors.Parse(error.code), 5);
            });
    };

    if (loadingAuthState) return <LoadingScreen/>

    return (
        <>
            <Layout style={{background: '#F4F4F4'}}>
                <div className="havas">
                    <img src="https://hvhavas.nl/wp-content/themes/hvhavas/dist/images/logo_havas.svg" alt="Havas"/>
                </div>
                <Content className="Kom-je-kijken__login" style={{padding: '0 25px', marginTop: 40}}>
                    <div className="Kom-je-kijken__login__content--signup">
                        <h2>Registreren</h2>
                        <h1>
                            <span>Kom je kijken?</span>
                        </h1>
                        <p>Registreer om Havas te supporten.</p>
                    </div>
                    <Form
                        {...layout}

                        name="signup-form"
                        onFinish={onFinish}
                    >
                        <FloatLabel label={"Volledige naam"} hasValue={nameHasValue}>
                            <Form.Item
                                name={'name'}
                                rules={[
                                    {required: true, message: 'Je bent vergeten je naam in te vullen.'}
                                ]}>
                                <Input onChange={value => setNameHasValue(value.target.value.length !== 0)}/>
                            </Form.Item>
                        </FloatLabel>
                        <FloatLabel label="E-mail" hasValue={emailHasValue}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {required: true, message: 'Je bent vergeten je e-mail in te vullen!'},
                                    {type: 'email', message: 'Je ingevoerde emailadres is geen geldig emailadres'}]}
                            >
                                <Input onChange={value => setEmailHasValue(value.target.value.length !== 0)}/>
                            </Form.Item>
                        </FloatLabel>
                        <FloatLabel label="Telefoon" hasValue={phoneHasValue}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {required: true, message: 'Je bent vergeten je telefoonnummer in te vullen!'}
                                ]}
                            >
                                <InputNumber
                                    onChange={value => setPhoneHasValue(value !== 0)}/>
                            </Form.Item>
                        </FloatLabel>


                        <FloatLabel label="Wachtwoord" hasValue={passwordHasValue}>
                            <Form.Item
                                name="password"
                                hasFeedback
                                rules={[{required: true, message: 'Je bent vergeten je wachtwoord in te vullen!'}]}
                            >
                                <Input.Password
                                    onChange={value => setPasswordHasValue(value.target.value.length !== 0)}/>
                            </Form.Item>
                        </FloatLabel>
                        <FloatLabel label={"Wachtwoord bevestigen"} hasValue={confirmPasswordHasValue}>
                            <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bevestig alsjeblieft je wachtwoord!',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Het wachtwoord komt niet overeen!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    onChange={value => setConfirmPasswordHasValue(value.target.value.length !== 0)}/>
                            </Form.Item>
                        </FloatLabel>
                        <Form.Item>
                            <Button style={{padding: 0}} type="link" onClick={() => history.push(ROUTES.login)}>
                                Al een account?
                            </Button>
                            <Button style={{float: 'right'}} type="primary"
                                    size="large" htmlType="submit">
                                Registreren
                            </Button>
                        </Form.Item>
                    </Form>
                </Content>
            </Layout>
        </>
    )
};

export default Signup;
