import React, {useState, useEffect, useContext} from "react";
import {useHistory} from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/firestore";
import {AuthContext} from "../../../helper/auth.helper";
import {ROUTES} from "../../../config/routes.config";

const DashboardPage = (props: any) => {
    const [userName, setUserName] = useState();
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const handleClick = (event: any) => {
        event.preventDefault();

        firebase
            .auth()
            .signOut()
            .then(res => {
                history.push(ROUTES.login);
            })
    };

    useEffect(() => {
        const {user} = authContext;
        // if (user) {
        //     setUserName(user.name);
        // }
    }, [authContext]);

    return (
        <div style={{textAlign: 'center'}}>
            <h1>Dashboard</h1>
            <h2>Welcome to Dashboard!</h2>
            <h3>{userName}</h3>
            <button onClick={handleClick}>Logout</button>
        </div>
    );
};

export default DashboardPage;
