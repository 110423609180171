import * as React from 'react';
import {Table} from "antd";

const renderTableCanteen = (canteen: any) => {
    return canteen ? 'Ja' : 'Nee';
};
const columns: any[] = [
    {
        title: 'Supporter',
        dataIndex: 'name',
        key: 'name1',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    }, {
        title: 'Volwassenen',
        dataIndex: 'houseHold',
        key: 'houseHold1',
        width: 30,
    }, {
        title: 'Kinderen',
        dataIndex: 'houseHoldYoung',
        key: 'houseHold2',
        width: 30,
    },
    {
        title: 'Kantine',
        dataIndex: 'canteen',
        key: 'canteen1',
        render: renderTableCanteen,
        width: 100,
        responsive: ['lg']
    },
    {
        title: 'Telefoonnummer',
        dataIndex: 'phone',
        key: 'phone1',
        responsive: ['lg'],
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email1',
        responsive: ['lg'],
    }
];

interface PresentSupportsTableProps {
    listOfSupporters: any[];
}

const PresentSupportsTable = (props: PresentSupportsTableProps) => {
    return <Table dataSource={props.listOfSupporters} columns={columns} rowKey="name"/>
};

export default PresentSupportsTable;
