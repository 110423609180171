import React from "react";
import {Route, Switch} from "react-router-dom";
import {DashboardPage} from "../components";

export const DashboardRoutes = () => {
    return (
        <Switch>
            <Route exact path="/admin/dashboard" component={DashboardPage}/>
        </Switch>
    );
}
