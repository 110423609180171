import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as firebase from 'firebase';
import "firebase/auth";
import "firebase/firestore";
import {AuthContext} from "../../../helper/auth.helper";
import {Button, Form, Input, Layout} from "antd";
import {ROUTES} from "../../../config/routes.config";
import {LoadingScreen} from "../../Loading/Loading";
import {ArrowRightOutlined} from '@ant-design/icons';

import './Login.scss';
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";
import {Toast} from "antd-mobile";
import {FirebaseErrors} from "../../../helper/FirebaseErrors.helper";

interface UserData {
    email: string;
}

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const Reset = () => {
    const [form] = Form.useForm();
    const [emailHasValue, setEmailHasValue] = useState();
    const {Content} = Layout;
    const {loadingAuthState} = useContext(AuthContext);
    const history = useHistory();

    const handleSubmit = (values: UserData) => {
        firebase
            .auth()
            .sendPasswordResetEmail(values.email)
            .then(() => {
                Toast.success('Binnen enkele minuten ontvang je een e-mail met daarin een persoonlijke link. Via de link kun je een nieuw wachtwoord opgeven', 5)
                history.push(ROUTES.login)
            })
            .catch(error => {
                Toast.fail(FirebaseErrors.Parse(error.code), 5);
            });
    };

    if (loadingAuthState) return <LoadingScreen/>;

    return (
        <Layout style={{background: '#F4F4F4'}}>
            <div className="havas">
                <img src="https://hvhavas.nl/wp-content/themes/hvhavas/dist/images/logo_havas.svg" alt="Havas"/>
            </div>
            <Content className="Kom-je-kijken__login" style={{padding: '0 25px', marginTop: 40}}>
                <div className="Kom-je-kijken__login__content">
                    <h2>Wachtwoord vergeten</h2>
                    <h1>
                        <span>Kan gebeuren!</span>
                    </h1>
                    <p>Vul je email in om hem te resetten.!</p>
                </div>
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    onFinish={handleSubmit}
                >
                    <FloatLabel label="E-mail" hasValue={emailHasValue}>
                        <Form.Item
                            name="email"
                            rules={[
                                {required: true, message: 'Je bent vergeten je e-mail in te vullen.'},
                                {type: "email", message: 'Je hebt geen geldig emailadres ingevoerd.'}
                            ]}
                        >

                            <Input onChange={value => setEmailHasValue(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <Form.Item>
                        <Button style={{padding: 0}} type="link" onClick={() => history.push(ROUTES.login)}>
                            Toch niet vergeten?
                        </Button>
                        <Button
                            style={{float: 'right'}}
                            type="primary"
                            size="large"
                            htmlType="submit">
                            Reset <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default Reset;
