import firebase from "../firebase";
import {Match, MatchRegistration} from "../types/match.type";
import {getUserById} from "./user.service";

const createMatch = (match: Match) => {
    const db = firebase.firestore();
    db.collection('matches')
        .doc()
        .set(match)
        .then(() => {
            console.log('Match is aangemaakt');
        })
        .catch(e => {
            console.error(e);
        });

};

const fetchMatches = () => {
    const db = firebase.firestore();
    return db.collection('matches').get()
        .then(snap => {
            const mapped = snap.docs.map(async (doc, index) => {
                const supporters = await getSupportersPerMatch(doc.id);
                const totalSupporters = supporters.map(item => {
                    return item.data()
                }).reduce((a, b) => a + (b.houseHold || 0), 0);
                const totalSupportersYoung = supporters.map(item => {
                    return item.data()
                }).reduce((a, b) => a + (b.houseHoldYoung || 0), 0);

                return {
                    ...doc.data(),
                    dateTime: doc.data().dateTime.toDate(),
                    totalSupporters,
                    totalSupportersYoung,
                    key: index,
                    id: doc.id,
                    ref: doc.ref
                };
            });

            return Promise.all(mapped);
        });
};

const getSupportersPerMatchRealtime = (matchId: string, callBackSucces: (item: any) => void) => {
    const db = firebase.firestore();
    return db
        .collection(`matches/${matchId}/users`)
        .onSnapshot({includeMetadataChanges: true},
            async (doc) => {
                const resolved = await Promise.all(doc.docs);
                callBackSucces(resolved);
            },
            (error) => console.error(error));
};

const getSupportersPerMatch = (matchId: string) => {
    const db = firebase.firestore();
    return db
        .collection(`matches/${matchId}/users`)
        .get()
        .then(snap => {
            return snap.docs;
        });
};

const getMatchAndUsersById = (matchId: string) => {
    const db = firebase.firestore();
    return db.collection('matches')
        .doc(matchId)
        .get()
        .then(async snap => {
            const supporters = await getSupportersPerMatch(snap.id);
            const listOfAllSupporters = supporters.map(async item => {
                const userProfile = await getUserById(item.id).then(item => item);
                return {
                    id: item.id,
                    ...item.data(),
                    ...userProfile
                }
            });

            const promised = Promise.all(listOfAllSupporters);
            return promised.then(item => {
                return {
                    ...snap.data(),
                    supporters: item,
                    id: snap.id,
                    ref: snap.ref
                }
            });
        })
};

const getMatchUserById = (matchId: any, userId = firebase.auth().currentUser!.uid) => {
    const db = firebase.firestore();
    return db.collection('matches')
        .doc(matchId)
        .collection("users")
        .doc(userId)
        .get()
        .then(snap => {
            return snap.data();
        });
};

const setUserPresentAtMatch = (matchId: string, userId: string) => {
    const db = firebase.firestore();
    return db.collection('matches')
        .doc(matchId)
        .collection("users")
        .doc(userId)
        .update({isPresent: true})
        .then()
        .catch(e => {
            console.log(e);
        })
};

const showPresentUsersByMatch = (matchId: string, callBackSucces: (item: any) => void) => {
    const db = firebase.firestore();
    return db.collection('matches')
        .doc(matchId)
        .collection("users")
        .where('isPresent', '==', true)
        .onSnapshot(
            {
                includeMetadataChanges: true
            },
            async (doc) => {
                const users = doc.docs.map(async item => {
                    const userProfile = await getUserById(item.id).then(item => item);
                    return {
                        ...item.data(),
                        ...userProfile
                    }
                });

                const resolved = await Promise.all(users);
                callBackSucces(resolved);
            },
            (error) => console.error(error)
        );
};

const getMatchRegistration = async (matchId: any, userId: any) => {
    const userProfile = await getUserById(userId);
    const matchUser = await getMatchUserById(matchId, userId);

    return {
        ...userProfile,
        ...matchUser
    }
};

const signUpForMatch = (matchRegistration: MatchRegistration) => {
    const db = firebase.firestore();
    return db
        .collection('matches')
        .doc(matchRegistration.id)
        .collection('users')
        .doc(firebase.auth().currentUser!.uid)
        .set(
            {
                canteen: matchRegistration.canteen || false,
                houseHold: matchRegistration.houseHold,
                houseHoldYoung: matchRegistration.houseHoldYoung,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date())
            }
        )
        .then(() => {
            return db
                .collection('users')
                .doc(firebase.auth().currentUser!.uid)
                .collection('matches')
                .doc(matchRegistration.id)
                .set({
                    timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                })
                .then(() => {

                })
                .catch(e => {
                    console.log(e);
                })

        })
        .catch(e => {
            console.log(e);
        })

};


const isAlreadySignedUp = async (matchId: string) => {
    const db = firebase.firestore();
    const doc = await db
        .collection(`users/${firebase.auth().currentUser!.uid}/matches`)
        .doc(matchId)
        .get();
    return doc.exists;
};

const deleteMatch = (matchId: string) => {
    const db = firebase.firestore();
    return db.collection('matches').doc(matchId).delete().then(function () {
        console.log("Document successfully deleted!");
    }).catch(function (error) {
        console.error("Error removing document: ", error);
    });
};

const deleteCurrentUserFromMatch = (match: Match) => {
    const db = firebase.firestore();
    return db.collection('matches')
        .doc(match.id)
        .collection('users')
        .doc(firebase.auth().currentUser!.uid)
        .delete()
        .then((args) => {
            console.log(args);
            return db.collection('users')
                .doc(firebase.auth().currentUser!.uid)
                .collection('matches')
                .doc(match.id)
                .delete()
                .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
};

export {
    createMatch,
    fetchMatches,
    signUpForMatch,
    deleteMatch,
    isAlreadySignedUp,
    getMatchAndUsersById,
    getMatchUserById,
    deleteCurrentUserFromMatch,
    getMatchRegistration,
    setUserPresentAtMatch,
    showPresentUsersByMatch,
    getSupportersPerMatchRealtime
};
