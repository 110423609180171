import * as React from 'react';
import QrReader from 'react-qr-reader'
import {Modal} from "antd";
import {getMatchRegistration, setUserPresentAtMatch} from "../../../services/match.service";

const MatchRegistrationAdminPage = () => {
    const [dataFound, setDataFound] = React.useState();

    const handleScan = async (data: string) => {
        if (data && !dataFound) {
            setDataFound(true);
            const [matchId, userId] = data.split(';');
            const userData = await getMatchRegistration(matchId, userId);
            if (userData) {
                success(userData);
                setUserPresentAtMatch(matchId, userId);
            }
        }
    };
    const handleError = (err: any) => {
        console.error(err)
    };

    const success = (user: any) => {
        Modal.success({
            onOk: () => setDataFound(false),
            content: (<>
                <br/>
                Persoon: {user.name}
                <br/>
                Aantal volwassenen: {user.houseHold}
                <br/>
                Aantal kinderen: {user.houseHoldYoung}
                <br/>
                Kantine bezoeken: {user.canteen ? 'Ja' : 'Nee'}
            </>),
        });
    };

    return (
        <div>
            <QrReader
                delay={500}
                onError={handleError}
                onScan={handleScan}
            />
        </div>
    )
};

export default MatchRegistrationAdminPage;
