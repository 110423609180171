import React, {useState} from "react";
import "./FloatingLabel.scss";

interface FloatingLabelProps {
    children: React.ReactNode,
    label: string;
    value?: string;
    hasValue?: boolean
}

const FloatLabel = (props: FloatingLabelProps) => {
    const [focus, setFocus] = useState(false);
    const {children, label, hasValue} = props;

    const labelClass =
        focus || hasValue ? "label label-float" : "label";

    return (
        <div
            className="float-label"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatLabel;
