export const firebaseConfig = {
    apiKey: "AIzaSyACnk9D6sGgd_Aw8-tkEotIODDjvKjhgNA",
    authDomain: "kom-je-kijken.firebaseapp.com",
    databaseURL: "https://kom-je-kijken.firebaseio.com",
    projectId: "kom-je-kijken",
    storageBucket: "kom-je-kijken.appspot.com",
    messagingSenderId: "592198094999",
    appId: "1:592198094999:web:7132c80776386417ead9d2",
    measurementId: "G-P7GM0QYYJX"
};
