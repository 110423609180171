import * as React from 'react';
import {Table} from "antd";
import {Match} from "../../../types/match.type";
import moment from 'moment';
import {useEffect} from "react";

import './MatchesTable.scss';


type MatchesTableProps = {
    matches: Match[];
    actions: {}
}

const renderTableDate = (date: any) => {
    return moment(date).format('DD-MM-YYYY HH:mm');
};

const sortTable = (a: any, b: any) => {
    return moment(a.dateTime).unix() - moment(b.dateTIme).unix()
};

const columns: any[] = [
    {
        title: 'Team',
        dataIndex: 'type',
        key: 'type',
        width: 100,
    }, {
        title: 'Team uit',
        dataIndex: 'awayTeam',
        key: 'awayTeam'
    },
    {
        title: 'Datum',
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: renderTableDate,
        defaultSortOrder: 'asc',
        sorter: sortTable,
        responsive: ['lg'],
    },
    {
        title: 'Toeschouwers aangmeld',
        dataIndex: 'totalSupporters',
        key: 'totalSupporters',
        width: 100,
        responsive: ['lg'],
    },
    {
        title: 'Maximaal aantal volwassenen',
        dataIndex: 'numberOfSpectatorsAdult',
        key: 'numberOfSpectatorsAdult',
        width: 150,
        responsive: ['lg'],
    },
    {
        title: 'Maximaal aantal kinderen',
        dataIndex: 'numberOfSpectatorsChild',
        key: 'numberOfSpectatorsChild',
        width: 150,
        responsive: ['lg'],
    },
];


const MatchesTable = (props: MatchesTableProps) => {
    useEffect(() => {
        console.log(props.matches);

        const actionExists = columns.find(item => {
            return item.key === 'action'
        });

        if (!actionExists) {
            columns.push(props.actions);
        }
    });

    return (
        <Table
            rowClassName={record => record.totalSupporters >= record.numberOfSpectatorsAdult ? 'Kom-je-kijken__MatchesTable--max-reached' : ''}
            dataSource={props.matches}
            columns={columns}></Table>
    )
};

export default MatchesTable


