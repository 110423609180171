import * as React from 'react';
import {Table} from "antd";

type UserTableProps = {
    users: any[];
}

const UserTable = (props: UserTableProps) => {
    const {Column} = Table;

    return (
        <Table dataSource={props.users}>
            <Column title="Volledige naam" dataIndex="name" key="name"/>
        </Table>
    )
};

export default UserTable
