import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as firebase from 'firebase';
import "firebase/auth";
import "firebase/firestore";
import {AuthContext} from "../../../helper/auth.helper";
import {Button, Form, Input, Layout} from "antd";
import {ROUTES} from "../../../config/routes.config";
import {LoadingScreen} from "../../Loading/Loading";
import {ArrowRightOutlined} from '@ant-design/icons';
import {FirebaseErrors} from "../../../helper/FirebaseErrors.helper";
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";
import {Toast} from "antd-mobile";

import './Login.scss';

interface UserData {
    email: string;
    password: string;
}

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const Login = () => {
    const [form] = Form.useForm();
    const [emailHasValue, setEmailHasValue] = useState();
    const [passwordHasValue, setPasswordHasValue] = useState();
    const {Content} = Layout;
    const {loadingAuthState, authenticated} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (authenticated) redirectToTargetPage();

    }, [authenticated, form]);

    const redirectToTargetPage = () => {
        history.push(ROUTES.matches);
    };

    const handleSubmit = (values: UserData) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(values.email, values.password)
            .then(async (userAuth: any) => {
                if (!userAuth) throw TypeError("Login failed");
            })
            .catch(error => {
                Toast.fail(FirebaseErrors.Parse(error.code), 5);
            });
    };

    if (loadingAuthState) return <LoadingScreen/>;

    return (
        <Layout style={{background: '#F4F4F4'}}>
            <div className="havas">
                <img src="https://hvhavas.nl/wp-content/themes/hvhavas/dist/images/logo_havas.svg" alt="Havas"/>
            </div>
            <Content className="Kom-je-kijken__login" style={{padding: '0 25px', marginTop: 40}}>
                <div className="Kom-je-kijken__login__content">
                    <h2>Inloggen</h2>
                    <h1>
                        <span>Welkom!</span>
                    </h1>
                    <p>Meld je aan om te komen kijken!</p>
                </div>
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    onFinish={handleSubmit}
                >
                    <FloatLabel label="E-mail" hasValue={emailHasValue}>
                        <Form.Item
                            name="email"
                            rules={[
                                {required: true, message: 'Je bent vergeten je e-mail in te vullen.'},
                                {type: "email", message: 'Je hebt geen geldig emailadres ingevoerd.'}
                            ]}
                        >

                            <Input onChange={value => setEmailHasValue(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Wachtwoord" hasValue={passwordHasValue}>
                        <Form.Item
                            name="password"
                            rules={[{required: true, message: 'Je bent vergeten je wachtwoord in te vullen!'}]}
                        >

                            <Input.Password
                                onChange={value => setPasswordHasValue(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <Form.Item>
                        <Button style={{padding: 0}} type="link" onClick={() => history.push(ROUTES.signup)}>
                            Nog geen account?
                        </Button>
                        <br/>
                        <Button style={{padding: 0}} type="link" onClick={() => history.push(ROUTES.reset)}>
                            Wachtwoord vergeten?
                        </Button>
                        <Button
                            style={{float: 'right'}}
                            type="primary"
                            size="large"
                            htmlType="submit">
                            Inloggen <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default Login;
