import React from "react";
import {Route, Switch} from "react-router-dom";
import {UserPage} from "../components";


export const UsersRoutes = () => {
    return (
        <Switch>
            <Route exact path="/admin/users" component={UserPage}/>
        </Switch>
    );
};
