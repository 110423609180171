import * as React from 'react';
import {Match as MatchType} from "../../../types/match.type";
import {Button} from "antd-mobile";
import './Match.scss';
import '../../../assets/images/logos/havas.svg';
import LoadImage from "../../../shared/components/LoadImage";
import moment from "moment";
import {getSupportersPerMatchRealtime, isAlreadySignedUp} from "../../../services/match.service";
import classNames from 'classnames';
import {List} from "antd";

interface MatchProps {
    qrCode: () => void
    match: MatchType;
    signUp: () => void;
}

const Match = (props: MatchProps) => {
    const [match, setMatch] = React.useState(props.match);
    const [supporters, setSupporters] = React.useState({
        adult: 0,
        young: 0
    });
    const [isLoading, setIsLoading] = React.useState<boolean>();
    const [alreadySigned, setAlreadySigned] = React.useState<boolean>();

    const checkIfAlreadySigned = React.useCallback(async () => {
        setIsLoading(true);
        const isAlreadysigned = await isAlreadySignedUp(match.id);
        setIsLoading(false);
        setAlreadySigned(isAlreadysigned);
    }, []);

    const updateSupportersCount = (items: any) => {
        const adult = items.map((item: any) => {
            return item.data()
        }).reduce((a: any, b: any) => a + (b.houseHold || 0), 0);
        const young = items.map((item: any) => {
            return item.data()
        }).reduce((a: any, b: any) => a + (b.houseHoldYoung || 0), 0);

        setSupporters({
            adult, young
        });
    };

    React.useEffect(() => {
        getSupportersPerMatchRealtime(props.match.id, updateSupportersCount);
        setMatch(props.match);
        checkIfAlreadySigned()
    }, [props.match]);

    const supporterClasses = classNames({
        'Match__supporters': true,
        'Match__supporters--limit-reached': supporters.adult >= match.numberOfSpectatorsAdult && supporters.young >= match.numberOfSpectatorsChild,
        'Match__supporters--limit-reached--adult': supporters.adult >= match.numberOfSpectatorsAdult,
        'Match__supporters--limit-reached--child': supporters.young >= match.numberOfSpectatorsChild
    });

    const renderSupportersDescription = () => {
        return (
            <div className={supporterClasses}>
                <p>
                    <span>Toeschouwers boven de 18:</span> {supporters.adult}/{match.numberOfSpectatorsAdult}
                </p>
                <p>
                    <span>Toeschouwers onder de 18:</span> {supporters.young}/{match.numberOfSpectatorsChild}
                </p>
            </div>
        )
    };

    const renderSignUpButton = () => {
        return (
            <Button
                disabled={supporters.adult >= match.numberOfSpectatorsAdult && supporters.young >= match.numberOfSpectatorsChild}
                className="Match__buttons__button Match__buttons__button--join"
                type="primary"
                inline
                size={"small"}
                onClick={props.signUp}
            >
                Kom je kijken?
            </Button>
        )
    };

    const renderSignedButtons = () => {
        return (
            <>
                <Button
                    className="Match__buttons__button Match__buttons__button--joined"
                    inline
                    size="small"
                    onClick={props.signUp}
                >
                    Ik ben erbij!
                </Button>

                <Button
                    className="Match__buttons__button Match__buttons__button--qr"
                    inline
                    size="small"
                    onClick={props.qrCode}
                >
                    Toon QRCode
                </Button>

                <Button
                    className="Match__buttons__button Match__buttons__button--health"
                    type="ghost"
                    inline
                    size="small"
                    onClick={() => window.open('https://www.covidtriage.nl/#/check', '_BLANK')}>
                    Gezondheidscheck
                </Button>
            </>
        )
    };

    return (
        <List.Item
            className={"MatchListItem"}
            key={match.id}
            actions={[renderSupportersDescription()]}
        >
            <List.Item.Meta
                title={match.type}
            />
            <div className="Match">
                <div className="Match__image">
                    <LoadImage imageName="havas"/>
                </div>
                <div className="Match__date">
                    {moment(props.match.dateTime).format('dddd')}
                    <br/>
                    {moment(props.match.dateTime).format('DD MMMM')}
                    <br/>
                    {moment(props.match.dateTime).format('HH:mm')}
                </div>
                <div className="Match__image">
                    <LoadImage imageName={props.match.image}/>
                </div>
            </div>
            <div className="Match__buttons">
                {!isLoading && !alreadySigned && renderSignUpButton()}
                {!isLoading && alreadySigned && renderSignedButtons()}
            </div>
        </List.Item>
    )
};

export default Match;
