import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as firebase from 'firebase';
import "firebase/auth";
import "firebase/firestore";
import {AuthContext} from "../../../helper/auth.helper";
import {Button, Form, Input, Layout} from "antd";
import {ROUTES} from "../../../config/routes.config";
import {LoadingScreen} from "../../Loading/Loading";

import './Login.scss';
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";
import {Toast} from "antd-mobile";
import {RouteHelper} from "../../../helper/route.helper";
import {FirebaseErrors} from "../../../helper/FirebaseErrors.helper";
import {ArrowRightOutlined} from "@ant-design/icons/lib";

interface UserData {
    password: string;
    confirmPassword: string;
}

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const ResetPassword = () => {
    const [form] = Form.useForm();
    const [passwordHasValue, setPasswordHasValue] = useState();
    const [confirmPasswordHasValue, setConfirmPasswordHasValue] = useState();
    const {Content} = Layout;
    const {loadingAuthState} = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (!RouteHelper.param().get('oobCode')) {
            redirectToTargetPage();
        }

    }, [RouteHelper.param().get('oobCode')]);

    const redirectToTargetPage = () => {
        history.push(ROUTES.login);
    };

    const handleSubmit = (values: UserData) => {
        const code: string | null = RouteHelper.param().get('oobCode');

        if (!code) return;

        firebase
            .auth()
            .confirmPasswordReset(code, values.password)
            .then(() => history.push(ROUTES.login))
            .catch(err => {
                Toast.fail(FirebaseErrors.Parse(err.code), 5);
            });
    };

    if (loadingAuthState) return <LoadingScreen/>;

    return (
        <Layout style={{background: '#F4F4F4'}}>
            <div className="havas">
                <img src="https://hvhavas.nl/wp-content/themes/hvhavas/dist/images/logo_havas.svg" alt="Havas"/>
            </div>
            <Content className="Kom-je-kijken__login" style={{padding: '0 25px', marginTop: 40}}>
                <div className="Kom-je-kijken__login__content">
                    <h2>Wachtwoord resetten</h2>
                    <h1>
                        <span>Kan gebeuren!!</span>
                    </h1>
                    <p>Vul je nieuwe wachtwoord in.</p>
                </div>
                <Form
                    {...layout}
                    form={form}
                    initialValues={{remember: true}}
                    onFinish={handleSubmit}
                >
                    <FloatLabel label="Wachtwoord" hasValue={passwordHasValue}>
                        <Form.Item
                            name="password"
                            hasFeedback
                            rules={[{required: true, message: 'Je bent vergeten je wachtwoord in te vullen!'}]}
                        >
                            <Input.Password
                                onChange={value => setPasswordHasValue(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label={"Wachtwoord bevestigen"} hasValue={confirmPasswordHasValue}>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Bevestig alsjeblieft je wachtwoord!',
                                },
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Het wachtwoord komt niet overeen!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                onChange={value => setConfirmPasswordHasValue(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <Form.Item>
                        <Button
                            style={{float: 'right'}}
                            type="primary"
                            size="large"
                            htmlType="submit">
                            Reset <ArrowRightOutlined/>
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default ResetPassword;
