import * as React from 'react';
import {Modal, Button, Form, Input, InputNumber, DatePicker} from 'antd';
import {createMatch} from "../../../services/match.service";
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";

interface AddMatchModalProps {
    onClose: () => void;
    isVisible: boolean;
}

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const dateFormat = "DD/MM/YYYY HH:mm";

const AddMatchModal = (props: AddMatchModalProps) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState();
    const [hasValueType, setHasValueType] = React.useState();
    const [hasValueImage, setHasValueImage] = React.useState();
    const [hasValueHomeTeam, setHasValueHomeTeam] = React.useState();
    const [hasValueAwayTeam, setHasValueAwayTeam] = React.useState();
    const [hasValueDatetime, setHasValueDatetime] = React.useState();
    const [hasValueNumberOfSpectatorsAdult, setHasValueNumberOfSpectatorsAdult] = React.useState();
    const [hasValueNumberOfSpectatorsChild, setHasValueNumberOfSpectatorsChild] = React.useState();

    const handleCancel = () => {
        props.onClose();
    };

    const onSubmit = React.useCallback(async (values) => {
        const mappedValues = {
            ...values,
            dateTime: values.dateTime.toDate()
        };

        setIsLoading(true);
        await createMatch(mappedValues);
        setIsLoading(false);
        handleCancel();
    }, []);

    return (
        <>
            <Modal
                visible={props.isVisible}
                title="Wedstrijd toevoegen"
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Return
                    </Button>,
                    <Button form="addMatch" key="submit" htmlType="submit" type="primary" loading={isLoading}>
                        Submit
                    </Button>,
                ]}
            >
                <Form {...layout}
                      form={form}
                      onFinish={onSubmit}
                      name="addMatch"
                >
                    <FloatLabel label="Type" hasValue={hasValueType}>
                        <Form.Item name={'type'} rules={[{required: true}]}>
                            <Input onChange={value => setHasValueType(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Afbeelding" hasValue={hasValueImage}>
                        <Form.Item name={'image'} rules={[{required: true}]}>
                            <Input onChange={value => setHasValueImage(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Team thuis" hasValue={hasValueHomeTeam}>
                        <Form.Item name={'homeTeam'} rules={[{required: true}]}>
                            <Input onChange={value => setHasValueHomeTeam(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Team uit" hasValue={hasValueAwayTeam}>
                        <Form.Item name={'awayTeam'} rules={[{required: true}]}>
                            <Input onChange={value => setHasValueAwayTeam(value.target.value.length !== 0)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Datum" hasValue={hasValueDatetime}>
                        <Form.Item name={'dateTime'} rules={[{required: true}]}>
                            <DatePicker showTime format={dateFormat}
                                        placeholder={""}
                                        onChange={value => setHasValueDatetime(value!.isValid())}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Toeschouwers boven 18" hasValue={hasValueNumberOfSpectatorsAdult}>
                        <Form.Item name={'numberOfSpectatorsAdult'} rules={[{required: true}]}>
                            <InputNumber onChange={value => setHasValueNumberOfSpectatorsAdult(value)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Toeschouwers onder 18" hasValue={hasValueNumberOfSpectatorsChild}>
                        <Form.Item name={'numberOfSpectatorsChild'} rules={[{required: true}]}>
                            <InputNumber onChange={value => setHasValueNumberOfSpectatorsChild(value)}/>
                        </Form.Item>
                    </FloatLabel>
                </Form>
            </Modal>
        </>
    );
};

export default AddMatchModal;
