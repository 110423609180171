export const ROUTES = {
    ADMIN: {
        dashboard: '/admin/dashboard',
        users: '/admin/users',
        matches: '/admin/matches',
        match: '/admin/matches',
        supporters: '/admin/matches/:id/supporters',
        registration: '/admin/registration',
    },
    login: '/auth/login',
    signup: '/auth/signup',
    reset: '/auth/reset',
    matches: '/matches'
}
