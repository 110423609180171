import * as React from 'react';
import AddMatchModal from "./AddMatch.modal";
import {Button, Layout, Popconfirm, Space} from "antd";
import {useState} from "react";
import {useEffect} from "react";
import {deleteMatch, fetchMatches} from "../../../services/match.service";
import MatchesTable from "./MatchesTable";
import {PlusOutlined, TeamOutlined} from '@ant-design/icons';
import {Match as MatchType} from "../../../types/match.type";
import {DeleteOutlined, PlayCircleOutlined} from "@ant-design/icons/lib";
import moment from "moment";
import {useHistory} from 'react-router-dom';
import {ROUTES} from "../../../config/routes.config";

const MatchesAdminPage = () => {
    const [showAddMatchModal, setAddMatchModal] = useState();
    const [matches, setMatches] = useState();
    const history = useHistory();

    useEffect(() => {
        updateMatches();

        return () => {
            // Anything in here is fired on component unmount.
        }
    }, []);

    const updateMatches = async () => {
        const listOfMatches = await fetchMatches();
        listOfMatches.sort((a, b) => moment(a.dateTime).diff(moment(b.dateTime)));

        console.log(listOfMatches);
        setMatches(listOfMatches);
    };

    const openAddMatchModal = () => {
        setAddMatchModal(true);
    };

    const onAddMatchModalClose = () => {
        setAddMatchModal(false);
    };

    const onDeleteMatch = (match: MatchType) => {
        if (match.id) {
            deleteMatch(match.id);
        }
    };

    const matchRegistration = (match: MatchType) => {
        history.push(ROUTES.ADMIN.match + '/' + match.id, {match: match.id})
    };

    const showSupporters = (match: MatchType) => {
        history.push(ROUTES.ADMIN.match + '/' + match.id + '/supporters', {match: match.id})
    };

    const tableActions = {
        title: 'Action',
        key: 'action',
        render: (match: MatchType) => (
            <Space size="middle">
                <Button shape="circle" onClick={() => matchRegistration(match)} icon={<PlayCircleOutlined/>}/>
                <Button shape="circle" onClick={() => showSupporters(match)} icon={<TeamOutlined/>}/>
            </Space>
        ),
    };

    return (
        <Layout style={{padding: '20px', height: '100%'}}>
            <Space style={{marginBottom: 16, float: 'right', position: 'relative', zIndex: 999}}>
                <Button size="large" shape="circle" onClick={openAddMatchModal} icon={<PlusOutlined/>}/>
            </Space>
            <AddMatchModal onClose={onAddMatchModalClose} isVisible={showAddMatchModal}/>
            <MatchesTable actions={tableActions} matches={matches}/>
        </Layout>
    )
};

export default MatchesAdminPage
