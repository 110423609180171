import * as React from 'react';

type LoadImageProps = {
    imageName: string;
}

const LoadImage = (props: LoadImageProps) => {
    const [image, setImage] = React.useState();
    React.useEffect(() => {
        loadImage()
    }, [props.imageName]);

    const loadImage = async () => {
        try {
            const {default: src} = await import(/* webpackMode: "lazy" */ `../../assets/images/logos/${props.imageName}.png`);
            setImage(src);
        } catch (e) {

        }
    };

    if (!image) {
        return (<>{props.imageName}</>)
    }

    return (
        <img src={image} alt="Logo"/>
    )
};

export default LoadImage;
