import React from "react";
import {Route, Switch} from "react-router-dom";
import MatchesAdminPage from "../components/Matches.admin.page";
import MatchAdminPage from "../components/Match.admin.page";
import MatchRegistrationAdminPage from "../components/MatchRegistration.admin.page";
import MatchSupporters from "../components/MatchSupporters.admin.page";

export const MatchAdminRoutes = () => {
    return (
        <Switch>
            <Route exact path="/admin/matches" component={MatchesAdminPage}/>
            <Route exact path="/admin/matches/:id" component={MatchAdminPage}/>
            <Route exact path="/admin/matches/:id/supporters" component={MatchSupporters}/>
            <Route exact path="/admin/registration" component={MatchRegistrationAdminPage}/>
        </Switch>
    );
};
