import * as React from 'react';
import {Button, Form, InputNumber, Modal} from "antd";
import {Switch} from "antd-mobile";
import {Match} from "../../../types/match.type";
import {useEffect, useState} from "react";
import FloatLabel from "../../../shared/components/FloatingLabel/FloatingLabel";

type SignUpMatchModalProps = {
    onClose: () => void,
    onRegistration?: (values: any) => void,
    onUnsubscribeMatch?: () => void,
    isVisible: boolean,
    isLoading?: boolean,
    match: Match
    matchDetails?: any,
}

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const SignUpMatchModal = (props: SignUpMatchModalProps) => {
    const [form] = Form.useForm();
    const [houseHold, setHouseHold] = useState(0);
    const [houseHoldYoung, setHouseHoldYoung] = useState(0);
    const handleCancel = () => {
        props.onClose();
    };

    useEffect(() => {
        setHouseHold(0);
        setHouseHoldYoung(0);
        form.resetFields();

        if (props.matchDetails) {
            setHouseHold(props.matchDetails.houseHold);
            setHouseHoldYoung(props.matchDetails.houseHoldYoung);
        }

    }, [props.matchDetails]);

    const onSubmitForRegistration = async (values: any) => {
        if (props.onRegistration) props.onRegistration(values)
    };

    const setFooterButton = () => {
        return props.matchDetails ? footerButtonsForEdit() : footerButtonsForSignUp()
    };

    const onUnsubscribeMatch = () => {
        if (props.onUnsubscribeMatch) props.onUnsubscribeMatch()
    };

    const footerButtonsForSignUp = () => [
        <Button form="signUpMatch" key="submit" htmlType="submit" type="primary" loading={props.isLoading}>
            Aanmelden
        </Button>,
    ];

    const footerButtonsForEdit = () => [
        <Button key="delete" type="link" danger onClick={onUnsubscribeMatch}>
            Afmelden
        </Button>,
        <Button form="signUpMatch" key="submit" htmlType="submit" type="primary" loading={props.isLoading}>
            Aanpassen
        </Button>,
    ];

    const houseHoldValidator = (rule: any, value: any, callback: any) => {
        const freePlaces = props.match.numberOfSpectatorsAdult - props.match.totalSupporters;
        if (value > freePlaces) {
            if (freePlaces === 0) {
                callback(`Er zijn geen plekken meer vrij`);
            } else if (freePlaces === 1) {
                callback(`Er is nog maar ${freePlaces} plek vrij`);
            } else {
                callback(`Er zijn nog maar ${freePlaces} plekken vrij`);
            }
        } else {
            callback();
        }
    };

    const houseHoldYoungValidator = (rule: any, value: any, callback: any) => {
        const freePlaces = props.match.numberOfSpectatorsChild - props.match.totalSupportersYoung;
        if (value > freePlaces) {
            if (freePlaces === 0) {
                callback(`Er zijn geen plekken meer vrij`);
            } else if (freePlaces === 1) {
                callback(`Er is nog maar ${freePlaces} plek vrij`);
            } else {
                callback(`Er zijn nog maar ${freePlaces} plekken vrij`);
            }
        } else {
            callback();
        }
    };

    return (
        <>
            {props.match && !props.isLoading &&
            <Modal
                destroyOnClose={true}
                visible={props.isVisible}
                title={`Aanmelden voor ${props.match.awayTeam}`}
                onCancel={handleCancel}
                footer={setFooterButton()}
            >
                <Form {...layout}
                      form={form}
                      onFinish={onSubmitForRegistration}
                      name="signUpMatch"
                >
                    <FloatLabel label="Aantal mensen boven de 18?" hasValue={true}>
                        <Form.Item
                            initialValue={houseHold}
                            extra="Uit hetzelfde huishouden"
                            dependencies={['houseHoldYoung']}
                            name={'houseHold'}
                            hasFeedback
                            rules={[
                                {validator: houseHoldValidator},
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value && !getFieldValue('houseHoldYoung')) {
                                            return Promise.reject('Je bent vergeten aan te geven met hoeveel je komt kijken');
                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}
                        >
                            <InputNumber max={6} onChange={(value: any) => setHouseHold(value)}/>
                        </Form.Item>
                    </FloatLabel>
                    <FloatLabel label="Aantal mensen onder de 18?" hasValue={true}>
                        <Form.Item
                            initialValue={houseHoldYoung}
                            name={'houseHoldYoung'}
                            dependencies={['houseHold']}
                            hasFeedback
                            rules={[
                                {validator: houseHoldYoungValidator},
                                ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (!value && !getFieldValue('houseHold')) {
                                            return Promise.reject('Je bent vergeten aan te geven met hoeveel je komt kijken');
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <InputNumber max={6} onChange={(value: any) => setHouseHoldYoung(value)}/>
                        </Form.Item>
                    </FloatLabel>
                    <Form.Item initialValue={props.matchDetails ? props.matchDetails.canteen : false}
                               name="canteen"
                               label="Wil je de kantine bezoeken?" valuePropName="checked">
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>}
        </>
    )
};

export default SignUpMatchModal
