import * as React from 'react';
import './App.scss';
import 'antd/dist/antd.css';
import 'antd-mobile/dist/antd-mobile.css';
import ApplicationRoutes from "./config/application.routes";
import {Layout} from "antd";
import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

type AppProps = {}
type AppState = {}

class App extends React.Component<AppProps, AppState> {
    render() {
        return (
            <Layout style={{height: '100vh'}}>
                <ApplicationRoutes/>
            </Layout>
        );
    }
}

export default App;
