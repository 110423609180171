import * as React from 'react';
import {useState} from "react";
import {useEffect} from "react";
import {getMatchAndUsersById} from "../../../services/match.service";
import {Layout, Table} from "antd";

const renderTableCanteen = (canteen: any) => {
    return canteen ? 'Ja' : 'Nee';
};

const columns: any[] = [
    {
        title: 'Supporter',
        dataIndex: 'name',
        key: 'name1',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
        title: '18+',
        dataIndex: 'houseHold',
        key: 'houseHold1'
    },
    {
        title: '18-',
        dataIndex: 'houseHoldYoung',
        key: 'houseHold1'
    },
    {
        title: 'Kantine bezoeken',
        dataIndex: 'canteen',
        key: 'canteen1',
        render: renderTableCanteen,

    },
];

const MatchAdminPage = (props: any) => {
    const [supporters, setSupporters] = useState();
    useEffect(() => {
        const matchId = props.location.state.match;
        updateMatchData(matchId);

        return () => {
            // Anything in here is fired on component unmount.
        }
    }, []);

    const updateMatchData = async (matchId: string) => {
        const matchDetails = await getMatchAndUsersById(matchId);
        setSupporters(matchDetails.supporters);
    };

    return (
        <Layout style={{padding: '20px', height: '100%'}}>
            <Table dataSource={supporters} columns={columns} rowKey="name" pagination={{
                pageSize: 15,
            }}/>
        </Layout>
    )
};

export default MatchAdminPage
