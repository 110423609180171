import React from "react";
import {Route, Switch} from "react-router-dom";
import MatchesPublicPage from "../components/Matches.public.page";

export const MatchPublicRoutes = () => {
    return (
        <Switch>
            <Route exact path="/matches" component={MatchesPublicPage}/>
        </Switch>
    );
};
