import * as React from 'react';
import {RouteProps, useHistory} from 'react-router';
import {Route, Redirect} from 'react-router-dom'
import {useContext} from "react";
import {AuthContext} from "./auth.helper";
import {LoadingScreen} from "../views/Loading/Loading";
import {LogoutOutlined} from '@ant-design/icons';
import {Drawer, NavBar, List} from "antd-mobile";
import {useState} from "react";
import firebase from "../firebase";
import {ROUTES} from "../config/routes.config";
import {QrcodeOutlined, MenuOutlined} from "@ant-design/icons/lib";

interface PrivateRouteProps extends RouteProps {
}

export class RouteHelper {
    static param = () => {
        return new URLSearchParams(window.location.search);
    };
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: RouteComponent, ...rest}) => {
    const {authenticated, loadingAuthState, isAdmin} = useContext(AuthContext);
    const history = useHistory();
    const [open, setOpen] = useState();

    const onMenuItemClicked = (route: string) => {
        setOpen(!open);
        history.push(route);
    };

    const sidebar = (<></>);
    const sidebarAdmin = (
        <>
            <List.Item key="0">
                <button onClick={() => onMenuItemClicked(ROUTES.matches)}>Wedstrijden</button>
            </List.Item>
            <List.Item key="1">
                <button onClick={() => onMenuItemClicked(ROUTES.ADMIN.matches)}>Overzicht wedstrijden</button>
            </List.Item>
            <List.Item key="2">
                <button onClick={() => onMenuItemClicked(ROUTES.ADMIN.users)}>Gebruikers</button>
            </List.Item>
        </>
    );

    const onLogout = () => {
        firebase
            .auth()
            .signOut()
            .then(res => {
                history.push(ROUTES.login);
            })
    };

    if (!RouteComponent) return null;
    if (loadingAuthState) return (<LoadingScreen/>);

    return (
        <Route
            {...rest}
            render={routeProps =>
                authenticated ? (
                    <div>
                        <NavBar
                            icon={isAdmin && <MenuOutlined/>}
                            onLeftClick={() => setOpen(!open)}
                            style={{height: '60px', backgroundColor: '#f1c40f', color: '#FFF'}}
                            rightContent={[
                                <span key="1" style={{cursor: "pointer"}} onClick={onLogout}>
                                    <LogoutOutlined style={{fontSize: '25px'}}/>
                                </span>,
                            ]}
                        >
                            Kom je kijken?
                        </NavBar>
                        <Drawer
                            className="my-drawer"
                            style={{minHeight: '100vh', background: '#FFF'}}
                            contentStyle={{background: 'none', paddingTop: 60}}
                            sidebar={isAdmin ? sidebarAdmin : sidebar}
                            open={open}
                            onOpenChange={() => setOpen(!open)}
                        >
                            <RouteComponent {...routeProps} />
                        </Drawer>
                    </div>
                ) : (
                    <Redirect to={{pathname: "/auth/login", state: {prevPath: rest.path}}}/>
                )
            }
        />
    );
};

export {PrivateRoute};

