import * as React from 'react';
import './Loading.scss';
import havasLogo from '../../assets/images/logos/havas.png'

export const LoadingScreen = () => {
    return (
        <div className="LoadingScreen">
            <img src={havasLogo} alt="HvHavas"/>
        </div>
    )
};
