import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {Login, SignUp} from "../components";
import Reset from "../components/Reset";
import {RouteHelper} from "../../../helper/route.helper";
import ResetPassword from "../components/ResetPassword";

export const AuthRoutes = () => {
    return (
        <Switch>
            <Route path="/auth/login" component={Login}/>
            <Route path="/auth/signup" component={SignUp}/>
            <Route path="/auth/reset" component={Reset}/>
            {RouteHelper.param().get('mode') === 'resetPassword' && <Route path="/auth/email/action" component={ResetPassword}/>}
            <Redirect to="/auth/login" from="/auth"/>
        </Switch>
    );
};
