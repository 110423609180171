import React, {useEffect, useState} from "react";
import firebase from "../firebase";
import {getCurrentUser} from "../services/user.service";

interface ContextProps {
    isAdmin: boolean;
    user: firebase.User | null;
    authenticated: boolean;
    setUser: any;
    loadingAuthState: boolean;
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({children}: any) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [user, setUser] = useState(null as firebase.User | null);
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user: any) => {
            if (!user) {
                setUser(user);
                setLoadingAuthState(false);
                return;
            }
            const cUser = await getCurrentUser();
            if (cUser) setIsAdmin(cUser.isAdmin);
            setUser(user);
            setLoadingAuthState(false);
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAdmin,
                authenticated: user !== null,
                setUser,
                loadingAuthState
            }}>
            {children}
        </AuthContext.Provider>
    );
}
